.App {
  margin: 5%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.heart {
  color: #ff0000;
}

.small {
  font-size: 0.75rem;
}

input:focus:invalid {
  border: 2px solid red;
}

p {
  overflow: hidden;
  text-overflow: ellipsis;
}

p:hover {
  text-overflow: unset;
}

.lighten {
  border: solid rgba(173, 216, 230, 0) 4px;
}

.lighten:hover {
  transition: 1s;
  background-color: black;
  color: lightgray;
  border: solid lightblue 4px;
  border-radius: 10px;
}


p p:hover {
  box-shadow: none;
}

a:link {
  color: cornflowerblue;
}

a:visited {
  color: chocolate;
}

a:hover {
  color: thistle;
}

a:active {
  color: thistle;
  background-color: darkgray;
}

.card {
  margin: 10px 20px;
  padding: 10px;
  border: 2px solid #3498db; /* Changed to a thicker, blue border */
  border-radius: 10px; /* Adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  width: 400px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.card:hover {
  transform: translateY(-5px); /* Slightly raises the card on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Increases shadow for lifted effect */
}
